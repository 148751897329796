@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Encode+Sans&family=Inter&family=Roboto&display=swap');


/* Font Reference */
/* .kanit-thin {
  font-family: "Kanit", sans-serif;
  font-weight: 100;
  font-style: normal;
} */

:root {
  --sp-background-100: #FFF;
  --sp-background-300: #E1E1E1;
  --sp-text-300: #33332D;
  --sp-contrast-blue-400: #82F7EB;
  --sp-contrast-blue-500: #82D2F7;
  --sp-contrast-yellow-400: #F7F382;
  --sp-contrast-green-400: #D4FCB5;
  --sp-contrast-orange-400: #EEAC5D;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

div {
  -webkit-transition: ease-out 0.2s;
  -moz-transition: ease-out 0.2s;
  -ms-transition: ease-out 0.2s;
  -o-transition: ease-out 0.2s;
  transition: ease-out 0.2s;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

body {
  box-sizing: border-box;
  font-size: 1rem;
  /* line-height: 1.8rem; */
  background-color: hsl(0, 0%, 93%);
}

.void-white {
  color: white;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  background-color: var(--sp-background-100);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p,
span,
li {
  color: var(--sp-text-300);
  font-family: 'Inter';
  line-height: 1.4rem;
}

h1,
h2,
h3,
h4 {
  color: var(--sp-text-300);
  font-family: "Kanit", sans-serif;
  font-weight: 400;
  font-style: normal;
}

/* Mobile First */
h1 {
  font-size: 1.2rem;
  font-weight: 400;
}

h2 {
  font-size: 1.6rem;
  font-weight: 600;
}

h3 {
  font-size: 1.4rem;
  font-weight: 400;
}

h4 {
  font-size: 1.2rem;
  font-weight: 400;
}

/* Regular */
@media screen and (min-width: 640px) {

  h1,
  h2 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.6rem;
    font-weight: 400;
  }

  h4 {
    font-size: 1.4rem;
    font-weight: 400;
  }

  p,
  li {
    line-height: 1.4rem;
  }

  body {
    font-size: 1.125rem;
  }

}

/* Large */
@media screen and (min-width: 1024px) {

  h1,
  h2 {
    font-size: 3rem;
  }

  h3 {
    font-size: 2rem;
    font-weight: 400;
  }

  h4 {
    font-size: 1.8rem;
    font-weight: 400;
  }
}

/* Wide */
@media screen and (min-width: 1280px) {}